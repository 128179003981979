import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { IconButton } from "@material-ui/core";
import {
  FiberManualRecord as FiberManualRecordIcon,
  FiberManualRecordOutlined as FiberManualRecordOutlinedIcon,
  MoreHoriz as MoreIcon,
} from "@material-ui/icons";
import {
  ActionList,
  Banner,
  Button,
  Card,
  ChoiceList,
  DataTable,
  Filters,
  Pagination,
  Popover,
  Spinner,
} from "@shopify/polaris";
import { useAdmin } from "context/admin";
import { useUser } from "context/user";
import useLocalStorage from "hooks/useLocalStorage";
import { useReactToPrint } from "react-to-print";
import { STORE_PROVIDER } from "shared/constants";
import { debounce } from "underscore";
import { orderShippingMethod, parseOrderData } from "util/helpers";
import PrintLineItemSummary from "./PrintLineItemSummary";
import PrintOrders from "./PrintOrders";
import ScheduleDeliveryModal from "./ScheduleDeliveryModal";
import SelectTimeSlotModal from "./SelectTimeSlotModal";
import OrderCollectionDate from "./components/OrderCollectionDate";
import OrderServiceType from "./components/OrderServiceType";
// import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

const OrderList = React.memo(
  ({ orders, deliveries, handleSelect, tab, title = "Orders", date }) => {
    // useWhyDidYouUpdate('OrderList', { orders, deliveries, handleSelect, tab, title });

    const REFUNDABLE_CREDIT_CARD_HOLD_TITLE = "Credit Card Hold";
    const pageSize = 10;

    const user = useUser();
    const admin = useAdmin();

    const pageKey = `ordersPage-${admin.selectedShopId}-${tab}`;
    const [page, setPage] = useLocalStorage(pageKey, 1);
    const [results, setResults] = useState(orders);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [lineItemSummary, setLineItemSummary] = useState([]);
    const [popover, setPopover] = useState(false);
    const [loadingLineItemSummary, setLoadingLineItemSummary] = useState(false);
    const [scheduleDeliveryFor, setScheduleDeliveryFor] = useState();
    const [changeDateFor, setChangeDateFor] = useState();
    const [schedulingDelivery, setSchedulingDelivery] = useState(false);
    const [changingOrderDate, setChangingOrderDate] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [location, setLocation] = useState(null);
    // enable location filter for sanagans
    const [shippingMethod, setShippingMethod] = useState(null);
    const [queryValue, setQueryValue] = useState(null);
    // Pickup instructions email is disabled for now
    const [disableEmailer, setDisableEmailer] = useState(true);
    const printOrderComponentRef = useRef();
    const shop = admin.selectedShop;
    const sanagans =
      shop.shopify_domain.indexOf("sanagans") !== -1 ||
      shop.shopify_domain.indexOf("dev-sa") !== -1 ||
      shop.shopify_domain.indexOf("friendly") !== -1;

    // 1. Add selectedFilterDate state at the top with other states
    const [selectedFilterDate, setSelectedFilterDate] = useState(null);

    // 1. Memoize the getUniqueDatesFromOrders function and its result
    const getUniqueDatesFromOrders = useCallback((ordersToProcess) => {
      const uniqueDatesMap = new Map();

      ordersToProcess.forEach((order) => {
        try {
          const { year, month, day } = parseOrderData(order);
          if (year && month && day) {
            const collectionDate = new Date(year, month - 1, day);
            const dateString = collectionDate.toLocaleDateString();
            uniqueDatesMap.set(dateString, true);
          }
        } catch (error) {
          console.log(error);
        }
      });

      return Array.from(uniqueDatesMap.keys()).sort(
        (a, b) => new Date(a) - new Date(b)
      );
    }, []);

    // 3. Define AvailablePickupLocations before filters
    const AvailablePickupLocations = orders
      .map((order) => {
        if (
          order.fulfillment_order &&
          order.fulfillment_order.assigned_location
        ) {
          return order.fulfillment_order.assigned_location?.address1;
        } else if (
          order.fulfillments &&
          order.fulfillments[0] &&
          admin.selectedShop.locations
        ) {
          let location_id = order.fulfillments[0].location_id;
          let thisLocation = admin.selectedShop.locations.filter(
            (l) => l.id === location_id
          );
          return thisLocation[0].address1;
        }
        return "Cancelled";
      })
      .filter((v, i, a) => v && a.indexOf(v) === i);

    const handlePrintOrders = useReactToPrint({
      content: () => printOrderComponentRef.current,
    });
    const printItemSummaryComponentRef = useRef();
    const handlePrintItemSummary = useReactToPrint({
      content: () => printItemSummaryComponentRef.current,
      onBeforeGetContent: () => {
        return new Promise((resolve, reject) => {
          if (!results) {
            resolve();
            return;
          }

          setLoadingLineItemSummary(true);
          const lineItemDict = {};

          results.forEach((order) => {
            order.line_items.forEach((line_item) => {
              if (
                line_item.title.indexOf(REFUNDABLE_CREDIT_CARD_HOLD_TITLE) ===
                -1
              ) {
                let item = lineItemDict[line_item.variant_id];

                if (item) {
                  lineItemDict[line_item.variant_id] = {
                    ...item,
                    total: (item.total || 0) + line_item.quantity,
                    order_counts: {
                      ...item.order_counts,
                      [order.name]: item[order.name]
                        ? item[order.name] + line_item.quantity
                        : line_item.quantity,
                    },
                  };
                } else {
                  lineItemDict[line_item.variant_id] = {
                    order_counts: {
                      [order.name]: line_item.quantity,
                    },
                    variant_title: line_item.variant_title,
                    title: line_item.title,
                    total: line_item.quantity,
                  };
                }
              }
            });
          });

          const list = Object.keys(lineItemDict)
            .map((variant_id) => lineItemDict[variant_id])
            .sort((a, b) => b.total - a.total);

          setLoadingLineItemSummary(false);
          setLineItemSummary(list);
          resolve(true);
        });
      },
      suppressErrors: true,
    });

    const [locationsLoaded, setLocationsLoaded] = useState(false);

    // Extract the complex expression to a variable
    const selectedShopLocations = admin.selectedShop?.locations;

    // Memoize the locations to avoid recalculating on every render
    const locationsMap = useMemo(() => {
      if (!selectedShopLocations) return {};
      return selectedShopLocations.reduce((map, location) => {
        map[location.id] = location.address1;
        return map;
      }, {});
    }, [selectedShopLocations]);

    useEffect(() => {
      if (selectedShopLocations) {
        setLocationsLoaded(true);
      }
    }, [selectedShopLocations]);

    const getLocationName = useCallback(
      (address1) => {
        if (!selectedShopLocations) {
          return null;
        }
        if (address1 === null || address1 === undefined) {
          return "Cancelled";
        }
        const locationName = selectedShopLocations.find(
          (l) => l.address1 === address1
        );
        return locationName?.name || "Unknown Location";
      },
      [selectedShopLocations]
    );

    const handleEmailPickupInstructions = () => {
      const emailKey = "sanagans_pickup_instructions";
      const ordersToEmail = orders.filter(
        (o) =>
          o.sent_emails.indexOf(emailKey) === -1 && o.service.type === "pickup"
      );
      if (date && ordersToEmail.length > 0) {
        admin.setConfirmation({
          content: `Are you sure you want to email pickup instructions? Emails will be sent to orders ${ordersToEmail
            .map((o) => o.name)
            .join(", ")}.`,
          handleOk: () => {
            admin.emailPickupInstructions(date.toLocaleDateString("en-ca"));
            setDisableEmailer(true);
          },
        });
      } else if (date) {
        admin.setToast(
          `There are no pickup orders that haven't already been emailed for ${date.toDateString()}.`
        );
      }
    };

    const serviceWindows =
      (admin.selectedShop ? admin.selectedShop.service_windows : []) || [];

    const handleShippingMethodChange = useCallback((value) => {
      // Only take the last one that was selected
      if (value && value.length === 0) {
        value = null;
      }
      if (value && value.length > 1) {
        value = [value[value.length - 1]];
      }
      setShippingMethod(value);
    }, []);
    const handleFiltersQueryChange = useCallback(
      (value) => setQueryValue(value),
      []
    );
    const handleLocationRemove = useCallback(() => setLocation(null), []);
    const handleShippingMethodRemove = useCallback(
      () => setShippingMethod(null),
      []
    );
    const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
    const handleFiltersClearAll = useCallback(() => {
      handleShippingMethodRemove();
      handleQueryValueRemove();
      handleLocationRemove();
    }, [
      handleShippingMethodRemove,
      handleQueryValueRemove,
      handleLocationRemove,
    ]);

    // create a useEffect to remove any objects with an empty key from appliedFilters
    // useEffect(() => {
    //   setAppliedFilters([]);
    // }, []);

    useEffect(() => {
      const key = "shippingMethod";
      if (shippingMethod) {
        setAppliedFilters((currentFilters) => {
          const updatedFilters = currentFilters.filter(
            (filter) => filter.key !== key
          );

          const newFilter = {
            key,
            label: `Shipping Method: ${shippingMethod}`,
            onRemove: handleShippingMethodRemove, // Ensure this is the correct function
          };

          if (updatedFilters.length > 0) {
            return [...updatedFilters, newFilter];
          } else {
            return [newFilter];
          }
        });
      } else {
        setAppliedFilters((currentFilters) =>
          currentFilters.filter((filter) => filter.key !== key)
        );
      }
    }, [shippingMethod, handleShippingMethodRemove]); // No need to include appliedFilters here
    // Ensure correct dependencies

    const handleLocationChange = useCallback((value) => {
      // Only take the last one that was selected
      if (value && value.length === 0) {
        value = null;
      }
      if (value && value.length > 1) {
        value = [value[value.length - 1]];
      }
      setLocation(value);
    }, []);

    useEffect(() => {
      const key = "location";
      if (location) {
        setAppliedFilters((currentFilters) => {
          const updatedFilters = currentFilters.filter(
            (filter) => filter.key !== key
          );

          const newFilter = {
            key,
            label: `Location: ${location}`,
            onRemove: handleLocationRemove, // Ensure this is the correct function
          };

          if (updatedFilters.length > 0) {
            return [...updatedFilters, newFilter];
          } else {
            return [newFilter];
          }
        });
      } else {
        setAppliedFilters((currentFilters) =>
          currentFilters.filter((filter) => filter.key !== key)
        );
      }
    }, [location, handleLocationRemove]); // Ensure correct dependencies

    const handleDateFilterRemove = useCallback(
      () => setSelectedFilterDate(null),
      []
    );

    // Update the filters array to include the date filter for the upcoming tab
    const filters = [
      {
        key: "shippingMethod",
        label: "Shipping Method",
        filter: (
          <ChoiceList
            title="Shipping Method"
            titleHidden
            choices={[
              { label: "Pickup", value: "Pickup" },
              { label: "Courier Delivery", value: "Courier Delivery" },
              { label: "Store Delivery", value: "Store Delivery" },
            ]}
            selected={shippingMethod || []}
            onChange={handleShippingMethodChange}
            allowMultiple
          />
        ),
        shortcut: true,
      },
    ];

    if (sanagans) {
      filters.unshift({
        key: "pickupLocation",
        label: "Pickup Location",
        filter: (
          <ChoiceList
            title="Pickup Location"
            titleHidden
            choices={AvailablePickupLocations.map((location) => ({
              label:
                location === "Cancelled"
                  ? "Cancelled"
                  : getLocationName(location),
              value:
                location === "Cancelled"
                  ? "Cancelled"
                  : getLocationName(location),
            }))}
            selected={location || []}
            onChange={handleLocationChange}
            allowMultiple
          />
        ),
        shortcut: true,
      });
    }

    // Add date filter only for the upcoming tab (tab === 8)
    if (title?.includes("Upcoming Orders")) {
      filters.splice(1, 0, {
        key: "date",
        label: "Date",
        filter: (
          <ChoiceList
            title="Date"
            titleHidden
            choices={getUniqueDatesFromOrders(orders).map((date) => ({
              label: date,
              value: date,
            }))}
            selected={selectedFilterDate ? [selectedFilterDate] : []}
            onChange={(selected) => {
              setSelectedFilterDate(selected[0]);
            }}
          />
        ),
        shortcut: true,
      });
    }

    // Add effect to handle date filter in appliedFilters
    useEffect(() => {
      const key = "date";
      if (selectedFilterDate) {
        setAppliedFilters((currentFilters) => {
          const updatedFilters = currentFilters.filter(
            (filter) => filter.key !== key
          );

          const newFilter = {
            key,
            label: `Date: ${selectedFilterDate}`,
            onRemove: handleDateFilterRemove,
          };

          return [...updatedFilters, newFilter];
        });
      } else {
        setAppliedFilters((currentFilters) =>
          currentFilters.filter((filter) => filter.key !== key)
        );
      }
    }, [selectedFilterDate, handleDateFilterRemove]);

    const debounced = useRef(
      debounce((query, ordersToSearch) => {
        setLoading(true);
        // console.log({ allProducts })
        if (ordersToSearch) {
          const r = ordersToSearch.filter(
            (order) => order.name.indexOf(`${query}`) !== -1
          );
          setPage(1);
          setResults(r);
        }
        setTimeout(() => {
          setLoading(false);
        }, 300);
      }, 300)
    );

    useEffect(() => {
      if (orders.length === 0) {
        return;
      }
      if (!queryValue && appliedFilters.length === 0) {
        setResults(orders);
        return;
      }

      // Create a map of filter types for faster lookup
      const filterMap = appliedFilters.reduce((acc, filter) => {
        acc[filter.key] = filter;
        return acc;
      }, {});

      let filtered = orders;

      // Only filter if we have filters
      if (appliedFilters.length > 0) {
        filtered = orders.filter((order) => {
          // Check date filter first as it's likely to exclude most orders
          if (filterMap.date) {
            const { year, month, day } = parseOrderData(order);
            const orderDate = new Date(year, month - 1, day);
            if (
              orderDate.toLocaleDateString() !==
              filterMap.date.label.split("Date: ")[1]
            ) {
              return false;
            }
          }

          // Check location filter
          if (filterMap.location) {
            let address = "";
            if (order.fulfillment_order?.assigned_location) {
              address = order.fulfillment_order.assigned_location.address1;
            } else if (order.fulfillments?.[0]) {
              const location = admin.selectedShop.locations.find(
                (l) => l.id === order.fulfillments[0].location_id
              );
              address = location?.address1 || "";
            }

            if (
              getLocationName(address) !==
              filterMap.location.label.split("Location: ")[1]
            ) {
              return false;
            }
          }

          // Check shipping method filter
          if (filterMap.shippingMethod) {
            const values = filterMap.shippingMethod.label
              .split("Shipping Method: ")[1]
              .split(",");
            const delivery = deliveries.entities[order.id];

            return values.some((value) => {
              if (value === "Pickup") return order.service.type === "pickup";
              if (value === "Store Delivery") {
                return (
                  order.service.type === "delivery" &&
                  delivery?.shipping_provider === STORE_PROVIDER
                );
              }
              if (value === "Courier Delivery") {
                return (
                  order.service.type === "delivery" &&
                  delivery?.shipping_provider !== STORE_PROVIDER
                );
              }
              return false;
            });
          }

          return true;
        });
      }

      if (queryValue?.length > 2) {
        debounced.current(queryValue, filtered);
      } else {
        setResults(filtered);
      }
    }, [
      orders,
      deliveries,
      queryValue,
      appliedFilters,
      getLocationName,
      admin.selectedShop.locations,
    ]);

    const customerName = (order) => {
      if (order.service.type === "delivery" && order.shipping_address) {
        return order.shipping_address.name;
      } else if (order.billing_address) {
        return order.billing_address.name;
      } else {
        return order.customer
          ? `${order.customer.first_name || ""} ${order.customer.last_name}`
          : "";
      }
    };

    const headers = [
      {
        type: "text",
        label: "",
        fn: (order) => {
          if (!user.isAdmin && !user.betaAccess) {
            return "";
          }
          return order.tags.indexOf("picked") !== -1 ? (
            <FiberManualRecordIcon style={{ color: "green" }} alt="Picked" />
          ) : (
            <FiberManualRecordOutlinedIcon />
          );
        },
      },
      {
        type: "text",
        label: "Order #",
        fn: (order) => (
          <Button
            size="slim"
            plain
            onClick={() => handleSelect(order)}
            color="transparent"
          >
            {order.name}
          </Button>
        ),
      },
      {
        type: "text",
        label: "Ready By",
        fn: (x) => <OrderCollectionDate order={x} />,
      },
      { type: "text", label: "Customer", fn: customerName },
      {
        type: "text",
        label: sanagans ? "Pickup Location" : "",
        fn: (order) => {
          if (!sanagans || !order) return null;
          if (!locationsLoaded) return "Loading...";

          if (order.cancelled_at) return "Cancelled";

          const locationId = order.fulfillments?.[0]?.location_id;
          const assignedLocation = order.fulfillment_order?.assigned_location;

          if (order.fulfillment_status === "fulfilled" && locationId) {
            return (
              getLocationName(locationsMap[locationId]) || "Unknown Location"
            );
          }

          if (assignedLocation) {
            return (
              getLocationName(assignedLocation.address1) || "Unknown Location"
            );
          }

          return "Cancelled";
        },
      },
      {
        type: "numeric",
        label: "Type",
        fn: (x) =>
          x.cancelled_at ? (
            "Cancelled"
          ) : (
            <OrderServiceType
              type={orderShippingMethod(x)}
              order={x}
              delivery={deliveries.entities[x.id]}
              navigateExternal={admin.navigateExternal}
            />
          ),
        sortable: true,
      },
      {
        type: "numeric",
        col: null,
        label: "",
        fn: (order) => (
          <Popover
            active={popover === order.id}
            activator={
              <IconButton onClick={() => setPopover(order.id)}>
                <MoreIcon style={{ cursor: "pointer" }} />
              </IconButton>
            }
            onClose={() => setPopover(null)}
          >
            <ActionList
              items={[
                {
                  content: <React.Fragment>View on Shopify</React.Fragment>,
                  onAction: () => handleView(order),
                },
                ...orderActions(order, deliveries.entities[order.id]),
              ]}
            />
          </Popover>
        ),
      },
    ];

    useEffect(() => {
      setRows(
        // use +(a.name.slice(1)) to remove the '#' from the name and convert to number
        results
          .sort((a, b) => (+a.name.slice(1) < +b.name.slice(1) ? 1 : -1))
          .slice((page - 1) * pageSize, page * pageSize + 1 - 1)
      );
    }, [results, page, pageSize]);

    useEffect(() => {
      setPage(1);
      handleFiltersClearAll(null);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);

    if (!orders.length) {
      return (
        <Card.Section sectioned>Your orders will show up here.</Card.Section>
      );
    }

    const scheduleDelivery = (order) => {
      setScheduleDeliveryFor(order);
    };

    const handleScheduleDelivery = (order, window, provider) => {
      setSchedulingDelivery(true);
      admin.scheduleDelivery(order.id, window.code, provider).finally(() => {
        setSchedulingDelivery(false);
        setScheduleDeliveryFor(false);
      });
    };

    const changeOrderDate = (order) => {
      setChangeDateFor(order);
    };
    const handleChangeOrderDate = (order, window) => {
      setChangingOrderDate(true);
      admin.changeOrderDate(order.id, window.code).finally(() => {
        setChangingOrderDate(false);
        setChangeDateFor(false);
      });
    };

    const handleCloseModal = () => {
      setScheduleDeliveryFor(false);
      setChangeDateFor(false);
    };

    const handleCancelDelivery = (order) => {
      admin.setConfirmation({
        content: "Are you sure you want to cancel this delivery?",
        handleOk: () => admin.cancelDelivery(order.id),
      });
    };

    const orderActions = (order, delivery) => {
      const links = [];
      const type = orderShippingMethod(order);
      const storeDelivery =
        type === "delivery" &&
        order.service &&
        order.service.provider === STORE_PROVIDER;
      const carrierDelivery =
        type === "delivery" &&
        delivery &&
        order.service &&
        order.service.provider !== STORE_PROVIDER &&
        (delivery.status === "pending" || delivery.status === "completed");
      const hasCarrier = !!admin.selectedShop.carrier;
      let allowSchedulingDelivery =
        (type === "pickup" ||
          storeDelivery ||
          !delivery ||
          (delivery && delivery.status === "cancelled")) &&
        hasCarrier;

      if (type === "pickup") {
        links.push({
          content: "Change Date",
          onAction: () => changeOrderDate(order),
        });
      }

      if (allowSchedulingDelivery) {
        links.push({
          content: "Schedule Delivery",
          onAction: () => scheduleDelivery(order),
        });
      }

      if (carrierDelivery && delivery.shipping_label) {
        const defaultURI =
          delivery.shipping_provider === "Sameday"
            ? `https://www.sddtrack.com/Order/Tools/OrderLabel.aspx?IsOnline=1&ReviewOrderOverride=1&OrderTrackingID_Str=${delivery.shipping_order_id}`
            : delivery.shipping_label.replace("http://", "https://");
        links.push({
          content: "Shipping Label",
          onAction: () => admin.navigateExternal(defaultURI),
        });
      }

      if (carrierDelivery && delivery.status_url) {
        links.push({
          content: "Delivery Status",
          onAction: () => admin.navigateExternal(delivery.status_url),
        });
      }

      if (carrierDelivery && order.service?.provider === "Trexity") {
        const trexityUrl = delivery.shipping_order_id
          ? `https://merchant.trexity.com/shipments/${delivery.shipping_order_id}/dashboard`
          : "https://merchant.trexity.com/";
        links.push({
          content: "View on Trexity",
          onAction: () => admin.navigateExternal(trexityUrl),
        });
      }

      if (carrierDelivery && order.service?.provider === "Nash") {
        const nashUrl = delivery.shipping_order_id
          ? `https://portal.usenash.com/active/${delivery.shipping_order_id}`
          : "https://portal.usenash.com/";
        links.push({
          content: "View on Nash",
          onAction: () => admin.navigateExternal(nashUrl),
        });
        // console.log(delivery);
      }

      if (type === "delivery" && delivery && delivery.status !== "cancelled") {
        links.push({
          content: "Cancel Delivery",
          onAction: () => handleCancelDelivery(order),
          destructive: true,
        });
      }

      return links;
    };

    const handleView = (order) => {
      admin.navigateToShopify(`admin/orders/${order.id}`);
      setPopover(null);
    };

    // Filter orders based on selected date
    useEffect(() => {
      if (selectedFilterDate) {
        const filteredOrders = orders.filter((order) => {
          const { year, month, day } = parseOrderData(order);
          const orderDate = new Date(year, month - 1, day);
          return orderDate.toLocaleDateString() === selectedFilterDate;
        });
        setResults(filteredOrders);
      } else {
        setResults(orders);
      }
    }, [selectedFilterDate, orders]);

    return (
      <React.Fragment>
        <Card.Header>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <span>
              Showing {results.length} Order{results.length !== 1 ? "s" : ""}
            </span>

            {sanagans && date ? (
              <Button
                loading={admin.loadingEmailPickupInstructions}
                size="slim"
                primary
                disabled={disableEmailer}
                onClick={handleEmailPickupInstructions}
              >
                Email Pickup Instructions
              </Button>
            ) : null}
            <Button
              loading={loadingLineItemSummary}
              size="slim"
              primary
              onClick={handlePrintItemSummary}
            >
              Print Item Summary
            </Button>
            <Button size="slim" primary onClick={handlePrintOrders}>
              Print Orders
            </Button>
          </div>
        </Card.Header>
        <Card.Section>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
          {loading ? (
            <Banner status="info">
              <Spinner accessibilityLabel="Loading orders" size="small" />
              Loading orders...
            </Banner>
          ) : (
            ""
          )}
          <DataTable
            columnContentTypes={headers.map((h) => h.type)}
            headings={headers.map((h) => h.label)}
            rows={rows.map((order) =>
              headers.map((header) =>
                header.fn(header.col ? order[header.col] : order)
              )
            )}
            footerContent={`Showing page ${page} of ${Math.ceil(
              results.length / pageSize
            )} pages`}
            verticalAlign="middle"
          />
          <div style={{ height: "100px", textAlign: "center" }}>
            <Pagination
              label="Orders"
              hasPrevious={page > 1}
              previousKeys={[74]}
              previousTooltip="j"
              onPrevious={() => {
                setPage(page - 1);
              }}
              hasNext={page * pageSize < results.length}
              nextKeys={[75]}
              nextTooltip="k"
              onNext={() => {
                setPage(page + 1);
              }}
            />
          </div>
        </Card.Section>

        <ScheduleDeliveryModal
          order={scheduleDeliveryFor}
          serviceWindows={serviceWindows}
          handleClose={() => handleCloseModal()}
          handleSave={handleScheduleDelivery}
          saving={schedulingDelivery}
          carrier={admin.selectedShop.carrier}
        />

        <SelectTimeSlotModal
          order={changeDateFor}
          title="Change Order Date"
          content="This will change the order date. Ensure that you have communicated the new date to your customer to avoid confusion."
          serviceWindows={serviceWindows}
          handleClose={() => handleCloseModal()}
          handleSave={handleChangeOrderDate}
          saving={changingOrderDate}
        />

        <PrintLineItemSummary
          line_items={lineItemSummary}
          title={`Item Summary: ${title}`}
          ref={printItemSummaryComponentRef}
        />

        <PrintOrders
          orders={results}
          deliveries={deliveries}
          products={admin.products}
          title={title}
          showPrices={
            shop?.shop_config?.print_order_show_prices === false ? false : true
          }
          ref={printOrderComponentRef}
        />
      </React.Fragment>
    );
  }
);

export default OrderList;
